import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CreateMutationResult,
  CreateQueryResult,
  injectMutation,
  injectQuery,
  injectQueryClient
} from '@tanstack/angular-query-experimental';
import { lastValueFrom } from 'rxjs';
import appVersion from '@/../../app_version.json';
import { MessageResponse } from '@/common/models/message-response.model';
import { StorageService } from '@/common/services';
import { RegisterRequestDTO, User } from '@/pages/auth/models';
import { LoginRequestDTO } from '@/pages/auth/models/login.model';
import { ChangeRecoveryPasswordDTO, CheckRecoveryPasswordDTO, RecoveryPasswordDTO } from '@/pages/auth/models/recovery-password.model';
import { SocialLoginRequestDTO } from '@/pages/auth/models/social-login.model';
import { AuthHttpService } from '@/pages/auth/services/http/auth-http.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _authLocalStorageToken = `${appVersion.version}-${environment.appEnv}-userAuth`;

  queryClient = injectQueryClient();

  constructor(
    private _authHttpService: AuthHttpService,
    private _storage: StorageService,
    private _router: Router,
  ) {
  }

  login(): CreateMutationResult<User, HttpErrorResponse, LoginRequestDTO> {
    return injectMutation<User, HttpErrorResponse, LoginRequestDTO>(() => ({
      mutationFn: variables => lastValueFrom(this._authHttpService.login(variables)),
      onSuccess: data => this._storage.store(this._authLocalStorageToken, data),
    }));
  }

  register(): CreateMutationResult<User, HttpErrorResponse, RegisterRequestDTO> {
    return injectMutation<User, HttpErrorResponse, RegisterRequestDTO>(() => ({
      mutationFn: variables => lastValueFrom(this._authHttpService.register(variables)),
      onSuccess: data => this._storage.store(this._authLocalStorageToken, data),
    }));
  }

  recoveryPassword(): CreateMutationResult<MessageResponse, HttpErrorResponse, RecoveryPasswordDTO> {
    return injectMutation<MessageResponse, HttpErrorResponse, RecoveryPasswordDTO>(() => ({ mutationFn: variables => lastValueFrom(this._authHttpService.recoveryPassword(variables)) }));
  }

  checkRecoveryPassword(): CreateMutationResult<MessageResponse, HttpErrorResponse, CheckRecoveryPasswordDTO> {
    return injectMutation<MessageResponse, HttpErrorResponse, CheckRecoveryPasswordDTO>(() => ({ mutationFn: variables => lastValueFrom(this._authHttpService.checkRecoveryPassword(variables)) }));
  }

  changeRecoveryPassword(): CreateMutationResult<User, HttpErrorResponse, ChangeRecoveryPasswordDTO> {
    return injectMutation<User, HttpErrorResponse, ChangeRecoveryPasswordDTO>(() => ({
      mutationFn: variables => lastValueFrom(this._authHttpService.changeRecoveryPassword(variables)),
      onSuccess: data => this._storage.store(this._authLocalStorageToken, data),
    }));
  }

  authGoogle(): CreateMutationResult<User, HttpErrorResponse, SocialLoginRequestDTO> {
    return injectMutation<User, HttpErrorResponse, SocialLoginRequestDTO>(() => ({
      mutationFn: variables => lastValueFrom(this._authHttpService.authGoogle(variables)),
      onSuccess: data => this._storage.store(this._authLocalStorageToken, data),
    }));
  }

  authLinkedin(): CreateMutationResult<User, HttpErrorResponse, SocialLoginRequestDTO> {
    return injectMutation<User, HttpErrorResponse, SocialLoginRequestDTO>(() => ({
      mutationFn: variables => lastValueFrom(this._authHttpService.authLinkedin(variables)),
      onSuccess: data => this._storage.store(this._authLocalStorageToken, data),
    }));
  }

  getProfile(): CreateQueryResult<User, HttpErrorResponse> {
    return injectQuery<unknown, HttpErrorResponse, User>(() => ({
      queryKey: ['user/profile'],
      queryFn: () => lastValueFrom(this._authHttpService.getProfile()),
    }));
  }

  logout(): void {
    this._storage.clear();
    this.queryClient.clear();

    this._router.navigate(['/auth/login']).then(r => {
      !r && console.error('Navigation failed in auth.service/logout');
    });
  }

  isLogged(): boolean {
    // TODO: Implement a better way to check if the user is logged in, for example if token still has lifetime
    return !!this._storage.retrieve(this._authLocalStorageToken);
  }

  updateUser(newUser: User | undefined): void {
    let user: User | null | undefined = this.getUser();

    if (user) {
      user = { ...user, ...newUser };
    } else {
      user = newUser;
    }

    this._storage.store(this._authLocalStorageToken, user);
  }

  getUser(): User | null {
    return this._storage.retrieve(this._authLocalStorageToken);
  }
}
